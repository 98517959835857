import React, { useEffect, useState } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../assets/images/logo.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import { Link } from 'react-router-dom';
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

import Contact from './Contact';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }

    useEffect(() => {
        // Define a function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Conditionally apply the class based on the state
    // const headerClass = isScrolled ? 'header_parent header_scrolled' : 'header_parent';

    return (
        < div>
            <div className='bg-[#222222] py-4 px-3 flex justify-around text-white  text-lg' >
                <div className=" hidden lg:flex md:flex gap-2 ">
                    < IoMail className='text-[#dd0000]' size={20} />
                    <span className="">Email</span>
                    <a href="mailto:psonewaycabs@gmail.com"> <span className="text-[#a1a8b3]">psonewaycabs@gmail.com</span></a>
                </div>
                 <div className="flex gap-2">
                     <FaPhone className='text-[#dd0000]' size={20}/>
                     <span className='hidden lg:inline-block md:inline-block'>Phone</span>
                    <a href="tel:+917339425446"><span className="text-[#a1a8b3]" >+917339425446</span></a>                
                </div>
           </div>
           <div className="flex  justify-around p-3 align-center">
                <div className="w-[45%] md:w-[15%] lg:w-[15%]">
                    <img src={logo} />
                </div>
                <div className="hidden lg:flex md:flex justify-center align-center p-5 gap-5 ">
                             
                             <NavList ul_class="text-black  flex p-[15px]  gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center " li_class="text-black hover:text-[#dd0000]" />
             
                </div>
                <div className="w-[30%] md:w-[15%] lg:w-[15%]  bg-[#dd0000]  text-white  headbtn flex justify-center items-center">
                <a href="#book_taxi_form">
                <span >Book Now</span></a>
                    </div>
               <div className='block lg:hidden cursor-pointer pr-8 text-white'>
                                 <span onClick={openNavBat} className="p-[15px]"><RxHamburgerMenu className=' text-[24px] md:text-[28px] text-[#dd0000]'/></span>
                </div>
                               < div className= {`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                             <div className='px-4 py-3 bg-[#ffc9cb]'>
                               <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
                             </div>
                               <div className='py-6 px-4'>
                                 <NavList ul_class="font-semibold text-[14px] text-black flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                                  <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
                                </div>
                            </div>

           </div>
           <div className="flex flex-col md:flex-row lg:flex-row ">
              <h1 className="bg-[#dd0000] font-bold text-2xl text-white w-full lg:w-[27%] md:[27%] text-center p-3">Hot Offers</h1>
              <marquee className="bg-[#222222] text-white flex justify-center items-center text-lg p-3">
                <div >
             <span className='p-5'>Chennai to Banglore Rs.5599/-</span>
                <span className='p-5'>Chennai to Madurai Rs.6499/-</span>            
                <span className='p-5'>Chennai to Salem Rs.4799/-</span>                
                <span className='p-5'>Chennai to Coimbatore Rs.7099/-</span>                
                <span className='p-5'>Chennai to Trichy Rs.8699/-</span>                
                 <span className='p-5'>Chennai to Tirunelveli Rs.8899/-</span>
                 <span className='p-5'>Chennai to Tiruppur Rs.6599/-</span>
                 <span className='p-5'>Madurai to Pondicherry Rs.4999/-</span>
                 <span className='p-5'>Chennai to Pondicherry Rs.2199/-</span>
                 </div >
              </marquee>
           </div>
        </div>

        // <div className='bg-[#333333]'>
        //     <div className='container  h-[150px] relation px-5 pb-6 flex items-center justify-between gap-x-5  mx-auto'>
        //         <div>
        //             <img src={logo} />
        //         </div>
        //         <div className='hidden lg:block md:block'>
        //             <Contact image={<IoMail className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#083245] outline-black" />} Label={"Email Address :"} contact={"info@gotriptaxi.in"} />

        //         </div>
        //         <div>

        //             <Contact image={<FaPhone className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#083245]"  />} Label={"Phone Number :"} contact={"+917200626263"} isphone='1' />
        //         </div>
        //         <div className='hidden lg:block md:block '>
        //             <a href="#book_taxi_form" className='th-btn bg-[#333333]'>Book Now</a>
        //         </div>

        //     </div>
        //     <div className='px-3 text-black bg-[#d1caca] absolute left-[50px] top-[100px]  right-[50px]'>

        //               <div className='relative '>
        //                         <div className='flex gap-x-5 justify-between items-center '>
        //                             <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
        //                               <NavList ul_class="text-black flex p-[15px]  gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="text-black" />
        //                              </div>
        //                              <div className='block lg:hidden cursor-pointer pr-8 text-white'>
        //                                 <span onClick={openNavBat} className="p-[15px]"><RxHamburgerMenu className=' text-[24px] md:text-[28px]'/></span>
        //                              </div>

        //                         </div>   

        //                    < div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
        //                       <div className='px-4 py-3 bg-[#ffc9cb]'>
        //                         <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
        //                       </div>
        //                        <div className='py-6 px-4'>
        //                          <NavList ul_class="font-semibold text-[14px] text-black flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
        //                          <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
        //                        </div>
        //                     </div>
        //               </div>

        //   </div>
        // </div>
        // <div className={headerClass}>
        //     <div >
        //         <div className='bg-[#5d5fef]'>
        //             <div className='container text-white mx-auto relative px-0 sm:px-4 md:px-8 lg:px-16 py-1 md:py-2 font-semibold'>
        //                 <marquee>Service starts @ Rs.14 per for details view our tariff</marquee>
        //             </div>
        //         </div>
        //         <div className=' bg-white'>
        //             <div className='relative bg-black'>
        //                 <div className='flex gap-x-5 justify-between items-center '>
        //                     <div className=' w-[65%]  md:w-[20%]  gap-x-5 '>
        //                     <Link to="/" className=' '>
        //                         <img src={logo} alt="logo" className=' w-[200px]  ' />
        //                         </Link>
        //                         </div> 
        //                     <div className='hidden font-medium  lg:inline-block w-[70%] pr-0 md:pr-10 lg:pr-16 xl:pr-20'>
        //                         <NavList ul_class="flex gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-white items-center nav_link_parent" li_class="" />
        //                     </div>
        //                     <div className='block lg:hidden cursor-pointer pr-8 text-white'>
        //                         <span onClick={openNavBat} ><RxHamburgerMenu className=' text-[24px] md:text-[28px]'/></span>
        //                     </div>
        //                 </div>
        //                 <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
        //                     <div className='px-4 py-3 bg-[#ffc9cb]'>
        //                         <img src={logo} alt="logo" className='w-[150px] mx-auto px-5' />
        //                     </div>
        //                     <div className='py-6 px-4'>
        //                         <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
        //                         <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Header